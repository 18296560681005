import { AppMessages } from '../types';

//@ts-ignore
export const appJA: AppMessages = {
    'app.btn_start': '開始',
    'app.btn_restart': '再開',
    'app.btn_cancel': 'キャンセル',
    'app.btn_next': '次へ',
    'app.btn_ok': 'OK',
    'app.btn_confirm': '確かめる',
    'app.btn_finish': '終了',
    'app.btn_back': '戻る',
    'app.dropdown_select': '選択して下さい...',
    'app.loading': 'loading',
    'app.response_yes': 'はい',
    'app.response_no': 'いいえ',
    'app.bevi_copyright_text_1': 'BEVI（the Beliefs, Events, and Values Inventory）は、著作権により保護されたテストです。BEVI及びBEVIのレポート、質問項目、質問内容又は尺度は、Craig N. Shealy, Ph.Dから、書面により明示的に許可されている場合を除き、その全体又は一部を、配布、複製、変更、応用、公開又は出版することはできません。',
    'app.bevi_copyright_text_2': '研究また教育のため、この日本語版BEVIを使用する独占的権利/権限は、Craig N. Shealy, Ph.Dから、西谷 元 教授（広島大学）に付与されています。',
    'app.empty.data': 'データがありません。',
    'app.systemerro': 'システムが不安定です。後で接続し直すか、今しばらくお待ちください。',
    'app.loading_document': 'loading document',
    'app.download_document': 'PDFをダウンロード',
    'app.download_excel': 'XLSXをダウンロード',
    'app.btn_search': '検索',
    'app.english': '英語',
    'app.catalan': 'カタロニア語',
    'app.spanish': 'スペイン語',
    'app.japanese': '日本語',
    'app.chinese-t': '中文(繁體)',
    'app.chinese-s': '中文(简体)',
    'app.true': 'True',
    'app.false': 'False',
    'app.save': '保存',
    'app.unknown_error': '未知のエラーが発生しました。BEVI管理責任者にご連絡ください。',
    'app.btn_reset': 'リセット',
    'app.search_placeholder': '検索テキストを入力',
    'app.notification.error_title': 'エラー',
    'app.btn_submit': '登録',
    'app.error.user_not_found': 'User not found',
    'app.save_with_success': '正常に保存',
    'app.swahili': 'スワヒリ語',
    'app.german': 'ドイツ語',
}
