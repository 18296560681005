import { AppMessages } from '../types';

export const appZHCN: AppMessages = {
    'app.btn_start': '開始',
    'app.btn_restart': '重新開始',
    'app.btn_cancel': '取消',
    'app.btn_next': '下一頁',
    'app.btn_ok': 'OK',
    'app.btn_finish': '完成',
    'app.btn_back': '返回',
    'app.dropdown_select': '請選擇……',
    'app.loading': 'loading',
    'app.response_yes': '是',
    'app.response_no': '不是',
    'app.bevi_copyright_text_1': 'BEVI（the Beliefs, Events, and Values Inventory）是受著作權法保護的測試。BEVI及BEVI的結果、每道問題及其內容、還有各項比例標準，除非得到Craig N. Shealy博士的明確書面許可，否則無權對其全部或一部分進行分發、複製、變更、應用、公開或出版。',
    'app.bevi_copyright_text_2': '以研究或教育為目的的BEVI中文版的使用權已由Craig N. Shealy博士授予西谷 元 教授（日本廣島大學）。',
    'app.empty.data': '沒有數據。',
    'app.systemerro': '系統網路不穩定。請稍後重新連接或稍等片刻。',
    'app.loading_document': '下載文檔',
    'app.download_document': '下載PDF',
    'app.download_excel': '下載XLSX',
    'app.btn_search': '搜索',
    'app.english': '英文',
    'app.spanish': '西班牙文',
    'app.japanese': '日文',
    'app.chinese-t': '中文（繁體）',
    'app.chinese-s': '中文（簡體）',
    'app.true': '真',
    'app.false': '假',
    'app.save': '保存',
    'app.unknown_error': '發生不明故障，請聯繫BEVI管理負責人。',
    'app.btn_reset': '重設',
    'app.search_placeholder': '請輸入搜索條件',
    'app.btn_confirm': 'Confirm',
    'app.catalan': 'Catalan',
    'app.btn_submit': 'Submit',
    'app.notification.error_title': 'Error',
    'app.error.user_not_found': 'User not found',
    'app.save_with_success': '保存成功',
    'app.swahili': '斯瓦希里语',
    'app.german': '德语',
}
