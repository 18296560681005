//export const serviceUrl = process.env.REACT_APP_BACKEND_URL ?? 'https://dev.takethebevi.com';
export const serviceUrl = process.env.REACT_APP_BACKEND_URL ?? 'https://localhost:5001';

export const successfulCode = 200;
export const unauthorizedCode = 401;
export const notFoundCode = 404;
export const internalCode = 500;
export const isMaintenence = false;
// If you need to inform users to do a maintenance, please, write down in a UTC date, otherwise, user undefined.
export const maintenanceStartDate = undefined; //'2021-09-05T03:00:00.000Z'; 
export const maintenanceEndDate = undefined; //'2021-09-06T03:00:00.000Z';

export const paginationDefaultPageSize = 20;

export const defaultNotificationDurationInSeconds = 2;
export const longNotificationDurationInSeconds = 10;
