import React from 'react';
import { Dropdown, DropdownButton, InputGroup } from 'react-bootstrap';
import { FaGlobe } from 'react-icons/fa';

import { colors } from '../config/colors';
import { IntlContext, LocalStorage_BeviLanguage } from '../IntlProviderWrapper';

export const LanguageSwitch = () => {
    const languageCode: string | null = localStorage.getItem(LocalStorage_BeviLanguage);

    let language: string = "";
    if (languageCode === 'ja') language = '日本語 - Japanese';
    else if (languageCode === 'zh-CN') language = '中文(简体) - Chinese(Simplified)'; //CN
    else if (languageCode === 'zh-TW') language = '中文(繁體) - Chinese(Traditional)'; //TW
    else if (languageCode === 'es') language = 'Español - Spanish';
    else if (languageCode === 'ca') language = 'Català - Catalan';
    else if (languageCode === 'de') language = 'Deutsch - German';
    else if (languageCode === 'sw') language = 'Kiswahili - Swahili';
    else language = 'English';

    const {
        switchToCatalan,
        switchToEnglish,
        switchToJapanese,
        switchToSpanish,
        switchToChineseTradicional,
        switchToChineseSimplified,
        switchToGerman,
        switchToSwahili,
    } = React.useContext(IntlContext);
    return (
        <InputGroup className='justify-content-end'>
            <InputGroup.Text style={styles.globeBackground}>
                <FaGlobe color={colors.darkSlateBlue} />
            </InputGroup.Text>
            <DropdownButton
                id='language-dropdown'
                title={language}
                variant={'shadow-none'}
                align='end'
            >
                <Dropdown.Item onClick={switchToEnglish}>English</Dropdown.Item>
                <Dropdown.Item onClick={switchToJapanese}>日本語 - Japanese</Dropdown.Item>
                <Dropdown.Item onClick={switchToChineseTradicional}>中文(繁體) - Chinese(Traditional)</Dropdown.Item>
                <Dropdown.Item onClick={switchToChineseSimplified}>中文(简体) - Chinese(Simplified)</Dropdown.Item>
                <Dropdown.Item onClick={switchToSpanish}>Español - Spanish</Dropdown.Item>
                <Dropdown.Item onClick={switchToCatalan}>Català - Catalan</Dropdown.Item>
                <Dropdown.Item onClick={switchToGerman}>Deutsch - German</Dropdown.Item>
                <Dropdown.Item onClick={switchToSwahili}>Kiswahili - Swahili</Dropdown.Item>
            </DropdownButton>
        </InputGroup>
    );
};

const styles = {
    globeBackground: {
        backgroundColor: 'transparent',
        borderWidth: 0,
    } as React.CSSProperties,
}
